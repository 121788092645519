














































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule, PurchaseModule } from '@/store/modules'
import { fixFloatingValue } from '@/services/functions'
import vSelect from 'vue-select'

@Component({
  components: {
    PurchaseAmountCard: () => import('./PurchaseAmountCard.vue'),
    vSelect
  }
})
export default class FreePurchaseAmountModal extends Vue {
  @AppModule.Getter currentBreakpoint
  @PurchaseModule.State promotion
  @PurchaseModule.State purchaseAmounts
  @PurchaseModule.Mutation setCurrentAmountSelected

  showModal = false
  price: number = 200
  selected = null
  fixFloatingValue = fixFloatingValue

  @Watch('selected')
  onSelectedChange(value) {
    this.price = this.purchaseAmounts.find(e => e.id === value.id).price
  }

  get purchaseAmountDetails() {
    return this.purchaseAmounts.find(e => e.price == this.price)
  }

  get ranges() {
    return this.purchaseAmounts.filter(e => e.id >= 7).map(e => e.price)
  }

  get totalCurrencyWithPromotion() {
    return Math.round(this.purchaseAmountDetails.totalCurrency * (1 + (this.promotion?.value || 0)))
  }

  get dropdownOptions() {
    return this.purchaseAmounts
      .filter(e => e.id >= 7)
      .map(e => {
        const shrinkedTitle = `${e.price}€ : ${e.currency} OC + ${e.bonus} OC bonus`
        let title = `${e.price}€ : ${e.currency} OC + ${e.bonus} bonus (+${fixFloatingValue(e.bonusPercentage * 100) }%)`
        const specialPromotion = ` + ${Math.round(e.totalCurrency * this.promotion.value)} bonus from promotion OC (+${this.promotion.value * 100 }%) added`

        if (this.promotion.enabled) {
          title += specialPromotion
        }

        return {
          id: e.id,
          title: ['xs', 'sm'].includes(this.currentBreakpoint) ? shrinkedTitle : title
        }
      })
  }

  async mounted() {
    this.$root.$on('openFreePurchaseAmountModal', () => {
      this.showModal = true
    })
    this.selected = this.dropdownOptions[0]
  }

  getPurchaseAmountIdOfPrice(price) {
    return this.purchaseAmounts.find(e => e.price == price).id
  }

  selectAmount() {
    this.setCurrentAmountSelected(this.purchaseAmountDetails.id)
    this.showModal = false
  }
}
